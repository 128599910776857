'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
const jsx_runtime_1 = require('react/jsx-runtime')
const ScanIcon = (props) => {
  return (0, jsx_runtime_1.jsx)(
    'img',
    {
      className: props.className,
      src: props.networkConfig.block_explorer.logo_uri,
      alt: props.networkConfig.block_explorer.display_name,
    },
    void 0
  )
}
exports.default = ScanIcon
